import React from "react";
import styled from "styled-components";

const LogoBox = styled.div`
  max-width: 200px;
  transform: translate(0, 51%);
`;

function Logo({ variant = "empty" }) {
  return (
    <LogoBox>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 891 453"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        xmlnsSerif="http://www.serif.com/"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeLinejoin: "round",
          strokeMiterlimit: 2
        }}
      >
        <g transform="matrix(1,0,0,1,-920.973,-797.767)">
          <g id="Eye" transform="matrix(6.0117,0,0,6.0117,656.027,2.92416)">
            <g
              id="Sclera"
              transform="matrix(0.0907721,0,0,0.0907721,-5.89617,76.8974)"
            >
              <path
                d="M1366,1387.1L1366,1438.58C1096.65,1438.58 550.477,1025.15 550.477,1025.15C550.477,1025.15 1096.65,609.422 1366,609.422L1366,660.904C1165.6,660.904 1002.9,823.602 1002.9,1024C1002.9,1033.39 1003.26,1042.7 1003.96,1051.92C1018.24,1239.29 1175,1387.1 1366,1387.1ZM1366,661.16L1366,609.422C1635.35,609.422 2181.52,1022.85 2181.52,1022.85C2181.52,1022.85 1635.35,1438.58 1366,1438.58L1366,1387.1C1566.4,1387.1 1729.1,1224.4 1729.1,1024C1729.1,823.602 1566.4,661.16 1366,661.16Z"
                style={{ fill: "rgb(240,224,255)" }}
              />
            </g>
            {variant !== "empty" && (
              <g
                id="Iris"
                transform="matrix(0.140181,0,0,0.140181,-12.6893,58.2651)"
              >
                <path
                  d="M932.991,561C1062.68,561 1167.98,666.296 1167.98,795.991C1167.98,925.685 1062.68,1030.98 932.991,1030.98C803.296,1030.98 698,925.685 698,795.991C698,666.296 803.296,561 932.991,561ZM932.991,692.22C990.263,692.22 1036.76,738.718 1036.76,795.991C1036.76,853.263 990.263,899.762 932.991,899.762C875.718,899.762 829.22,853.263 829.22,795.991C829.22,738.718 875.718,692.22 932.991,692.22Z"
                  style={{ fill: "url(#_Radial1)" }}
                />
              </g>
            )}
            <g
              id="Pupil"
              transform="matrix(0.166342,0,0,0.166342,-104.367,1.23054)"
            >
              <circle
                cx="1339.61"
                cy="1013.68"
                r="99.605"
                style={{ fill: "rgb(240,224,255)" }}
              />
            </g>
          </g>
        </g>
        <defs>
          <radialGradient
            id="_Radial1"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(234.991,0,0,234.991,930.283,791.139)"
          >
            <stop
              offset="0"
              style={{ stopColor: "rgb(240,224,107)", stopOpacity: 1 }}
            />
            <stop
              offset="0.01"
              style={{ stopColor: "rgb(215,229,91)", stopOpacity: 1 }}
            />
            <stop
              offset="0.01"
              style={{ stopColor: "rgb(152,241,49)", stopOpacity: 1 }}
            />
            <stop
              offset="0.01"
              style={{ stopColor: "rgb(79,255,0)", stopOpacity: 1 }}
            />
            <stop
              offset="0.68"
              style={{ stopColor: "rgb(70,106,225)", stopOpacity: 1 }}
            />
            <stop
              offset="0.84"
              style={{ stopColor: "rgb(159,50,192)", stopOpacity: 1 }}
            />
            <stop
              offset="0.93"
              style={{ stopColor: "rgb(192,30,128)", stopOpacity: 1 }}
            />
            <stop
              offset="1"
              style={{ stopColor: "rgb(206,21,101)", stopOpacity: 1 }}
            />
          </radialGradient>
        </defs>
      </svg>
    </LogoBox>
  );
}

export default Logo;
