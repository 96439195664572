import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./App.css";
import Logo from "./Logo";
import styled, { keyframes } from "styled-components";
import { useSpring, animated } from "react-spring";
import {
  HashRouter as Router,
  Route,
  Switch,
  useHistory
} from "react-router-dom";

const fancyBackgroundPattern = `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAaUlEQVQoU2WP0RGAMAhDkwUYoI6h+0+gY9QBskA8erZXK19wIS9AADuACwAkOSI2AHfOts1scknSGRF9LrYrsybn0UnNSTYSX+wg2T67EUBJoXwyyUFK8y9zvaktzJnrTXzFQZJUp2/2B4wOSCeSg9ChAAAAAElFTkSuQmCC) repeat`;
const defaultBackground = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAaUlEQVQoU2WP0RGAMAhDkwUYoI6h+0+gY9QBskA8erZXK19wIS9AADuACwAkOSI2AHfOts1scknSGRF9LrYrsybn0UnNSTYSX+wg2T67EUBJoXwyyUFK8y9zvaktzJnrTXzFQZJUp2/2B4wOSCeSg9ChAAAAAElFTkSuQmCC`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const PageLayout = styled(Box)`
  height: 100vh;
  width: 100vw;
  background: #101010;
  display: flex;
  padding: 2rem;
  position: relative;
  box-sizing: border-box;
`;

const Title = styled.h1`
  text-align: center;
`;

const Label = styled.label`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
`;

const LabelText = styled.span`
  margin: 0.5rem 0;
`;

const PasswordInput = styled.input`
  background: #202020;
  color: #f1f1f1;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 8px;
  min-width: 250px;
`;

function PasswordForm({ onSuccess = () => {} }) {
  const betaPass = "miouxa";
  const handleInput = e => {
    if (e.target.value === betaPass) {
      onSuccess();
    }
  };
  return (
    <Label>
      <LabelText>Beta Password</LabelText>
      <PasswordInput type="password" onInput={handleInput} />
    </Label>
  );
}

const Card = styled.div`
  max-width: 600px;
  height: 100%;
  margin: 0 auto;
  color: #f1f1f1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background: ${fancyBackgroundPattern};
  border-radius: 4px;
  box-shadow: 0px 4px 10px #202020;
  display: flex;
`;

const Text = styled.p`
  font-size: 1.8rem;
  padding: 0 2rem;
`;

const swoosh = keyframes`
  0% {
    transform-origin: left center;
  }

  20% {
    transform: skewX(-20deg) scaleX(1);
    transform-origin: left center;
  }

  21% {
    transform-origin: right center;
  }

  50% {
    transform: skewX(-20deg) scaleX(0);
    transform-origin: right center;
  }
`;

const fill = keyframes`
  0% {
    background-color: rgba(#0e3876, 0);
  }

  20% {
    opacity: 0.5;
    border-width: 15px;
    border-color: darken(#0e3876, 10%);
  }

  100% {
    opacity: 0.5;
    border-width: 1px;
    background-color: rgba(#0e3876, 0.5);
  }
`;

const UploadButton = styled.div`
  cursor: pointer;
  outline: none;
  backface-visibility: hidden;
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #fff;

  & > span {
    text-align: center;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 20px 50px;
    z-index: 3;
  }

  &:before,
  & > span:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) skewX(-20deg);
    width: 100%;
    height: 50px;
    border: 1px solid #202020;
    z-index: 2;
  }
  & > span:before {
    display: block;
    transform: translate(-50%, -50%) skew(-20deg);
    transform-origin: center center;
    z-index: -1;
  }

  &::after {
    transition: all 100ms ease-out;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transform: skewX(-20deg) scaleX(0);
    width: 100%;
    height: 100%;
    background-color: #202020;
    border: 1px solid transparent;
    z-index: 1;
  }

  &:hover > span:before {
    animation: ${fill} 0.5s ease-out;
    animation-fill-mode: forwards;
  }

  &:hover:after {
    animation: ${swoosh} 0.7s ease-in;
  }

  &:active:before {
    background-color: darken(#202020, 20%);
  }
`;

function CardText({ status = "offline" }) {
  const textByStatus = {
    offline: "Use state of the art Artificial Inteligence to analyze your eye",
    online:
      "Upload an image and let our state of the art Artificial Inteligence, ABS, EBD algorithm analyzse your eye"
  };

  return <Text>{textByStatus[status]}</Text>;
}

function useStatePersistance(initialValue, persistanceKey) {
  const value = window.localStorage.getItem(persistanceKey);
  const [state, setState] = React.useState(value || initialValue);

  React.useEffect(() => {
    window.localStorage.setItem(persistanceKey, state);
  }, [state, persistanceKey]);

  return [state, setState];
}

const FileUploadInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const FileLabel = styled.label`
  display: flex;
  justify-content: center;
`;

const getFileFromInputEvent = (event, callback) => {
  event.stopPropagation();
  event.preventDefault();

  let { files } = event.target;

  let reader = new FileReader();
  reader.onload = e => callback(e.target.result);
  reader.readAsDataURL(files[0]);
};

function UploadForm({ onUpload }) {
  const onInput = React.useCallback(
    evt => {
      getFileFromInputEvent(evt, image => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
          onUpload(image);
        };
      });
    },
    [onUpload]
  );

  return (
    <FileLabel>
      <FileUploadInput type="file" onChange={onInput} />
      <UploadButton>
        <span>Upload</span>
      </UploadButton>
    </FileLabel>
  );
}

const getRandomResult = () => {
  const results = ["Foo", "MD", "Marijuana-free Christmas snow"];

  const randomIndex = (Math.random() * results.length) & results.length;
  return results[randomIndex];
};

const AnimatedInfo = animated.div;

function ImageInfo() {
  const items = [
    "Processing the image",
    "Using advanced artificial intelligence",
    "Analyzing some more"
  ];

  const { index } = useSpring({
    from: { index: 0 },
    to: { index: 3 },
    config: {
      duration: 5000
    }
  });

  return (
    <Results>
      <AnimatedInfo>
        {index.interpolate(x => {
          if (x <= 1) {
            return items[0];
          }
          if (x <= 2) {
            return items[1];
          }
          if (x < 3) {
            return items[2];
          }
          if (x == 3) {
            return getRandomResult() || "MD";
          }
        })}
      </AnimatedInfo>
    </Results>
  );
}

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  position: fixed;
`;

function Portal(props) {
  const { children, container = document && document.body } = props;

  let [mountNode, setMountNode] = useState(null);

  useEffect(() => {
    setMountNode(container);

    return () => undefined;
  }, [container]);

  return mountNode ? ReactDOM.createPortal(children, mountNode) : null;
}

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const Modal = styled.div`
  max-width: 600px;
  border-radius: 6px;
  background: black;
  color: white;
  padding: 1rem;
  position: relative;
  width: 80%;
  height: 90%;
`;

const CloseButton = styled.button`
  padding: 1rem 2rem;
  color: white;
  font-size: 1rem;
  font-weight: 900;
  background: rgba(0, 0, 0, 0.91);
  outline: none;
  border-radius: 0 4px 0 4px;
  border: none;
  font-family: inherit;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const Results = styled.div`
  position: absolute;
  bottom: 0;
  padding: 1rem;
  background: white;
  left: 0;
  color: black;
  border-radius: 0 4px;
  font-size: 2rem;
`;

function ImageModal({ open, onClose, image }) {
  return open ? (
    <Portal>
      <div>
        <Backdrop onClick={onClose} />
        <ModalContainer>
          <Modal
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center"
            }}
          >
            <CloseButton onClick={onClose}>Close</CloseButton>
            <ImageInfo />
          </Modal>
        </ModalContainer>
      </div>
    </Portal>
  ) : null;
}

function UploadImageView() {
  const [image, setImage] = React.useState(null);
  const [isOpen, setIsOpen] = useState();

  return (
    <PageLayout>
      <Box>
        <Title style={{ color: "white" }}>MATI ANALYSIS</Title>
        <Logo variant={image ? "" : "empty"} />
      </Box>
      <Card
        style={{
          backgroundImage: `url(${defaultBackground})`,
          backgroundSize: "",
          backgroundRepeat: "repeat",
          backgroundPosition: "center center"
        }}
      >
        <Text style={{ marginTop: "50px" }}>
          Use state of the art Artificial Inteligence to analyze your eye
        </Text>
      </Card>
      <UploadForm
        onUpload={image => {
          setImage(image);
          setIsOpen(true);
        }}
      />
      <ImageModal
        open={isOpen}
        image={image}
        onClose={() => setIsOpen(false)}
      />
    </PageLayout>
  );
}

function LogInView() {
  let history = useHistory();
  function handleLoginFormSuccess() {
    history.push("/upload");
  }

  return (
    <PageLayout>
      <Box>
        <Title style={{ color: "white" }}>MATI ANALYSIS</Title>
        <Logo variant="empty" />
      </Box>
      <Card
        style={{
          backgroundImage: `url(${defaultBackground})`,
          backgroundSize: "",
          backgroundRepeat: "repeat",
          backgroundPosition: "center center"
        }}
      >
        <Text>
          Use state of the art Artificial Inteligence to analyze your eye
        </Text>
      </Card>
      <PasswordForm onSuccess={handleLoginFormSuccess} />
    </PageLayout>
  );
}

function AnalysisImageView() {
  return null;
}

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LogInView />
        </Route>
        <Route path="/upload">
          <UploadImageView />
        </Route>
        <Route path="/analysis">
          <AnalysisImageView />
        </Route>
      </Switch>
    </Router>
  );
}
